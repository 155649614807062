<template>
    <vue-ueditor-wrap v-model="binds" :config="config" />
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap';
export default {
    components: {VueUeditorWrap},

    name: "page-ueditor",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        /* 初始值 */
        value: {type: String,default:''},
        height: {type: Number,default:0},
    },
    data() {
        return {
            binds:'',
            config:{
                // 上传文件接口
                serverUrl: $config.apihost +'/upload/index.php',
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: './ueditor/',
                // 初始容器宽度
                initialFrameWidth: '100%',
                scaleEnabled:false,
                autoFloatEnabled:false,
                toolbars: [
                    [
                        'fullscreen', 'source', '|', 'undo', 'redo', '|',
                        'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
                        'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
                        'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
                        'directionalityltr', 'directionalityrtl', 'indent', '|',
                        'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
                        'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
                        'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'gmap', 'insertframe', 'insertcode', 'pagebreak', 'template', 'background', '|',
                        'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
                        'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
                        'print', 'preview', 'searchreplace', 'drafts'
                    ]
                ],
                // 'fontfamily':[
                //     { label:'',name:'songti',val:'宋体,SimSun'},
                //     { label:'仿宋',name:'fangsong',val:'仿宋,FangSong'},
                //     { label:'仿宋_GB2312',name:'fangsong',val:'仿宋_GB2312,FangSong'},
                //     { label:'',name:'kaiti',val:'楷体,楷体_GB2312, SimKai'},
                //     { label:'',name:'yahei',val:'微软雅黑,Microsoft YaHei'},
                //     { label:'',name:'heiti',val:'黑体, SimHei'},
                //     { label:'',name:'lishu',val:'隶书, SimLi'},
                //     { label:'',name:'andaleMono',val:'andale mono'},
                //     { label:'',name:'arial',val:'arial, helvetica,sans-serif'},
                //     { label:'',name:'arialBlack',val:'arial black,avant garde'},
                //     { label:'',name:'comicSansMs',val:'comic sans ms'},
                //     { label:'',name:'impact',val:'impact,chicago'},
                //     { label:'',name:'timesNewRoman',val:'times new roman'}
                // ]
            }
        };
    },
    mounted() {
        this.binds = this.value || '';
        if( this.height<0 ){
            this.$set(this.config,'autoHeightEnabled',false);
            this.$set(this.config,'initialFrameHeight',this.$el.parentElement.offsetHeight-170);
        }else if( this.height>0 ){
            this.$set(this.config,'autoHeightEnabled',false);
            this.$set(this.config,'initialFrameHeight',this.height);
        }else{
            this.$set(this.config,'autoHeightEnabled',true);
        }
        
    },
    watch: {
        value(value) {
            this.binds = this.value;
        },
        binds(binds) {
            this.$emit('change',binds);
        }
    }
};
</script>